import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

type Store = State & Actions;
type State = {
	sharingHandlerVk?: () => void;
	sharingHandlerOk?: () => void;
	sharingHandlerTelegram?: () => void;
	linkCopyEnabled?: boolean;
};
type Actions = {
	enableShareVk: (handler: () => void) => void;
	enableShareOk: (handler: () => void) => void;
	enableShareTelegram: (handler: () => void) => void;
	enableLinkCopy: () => void;
};

const useSharingHandlersStore = create<Store>()(
	immer((set) => ({
		enableShareVk: (handler) =>
			set((state) => {
				state.sharingHandlerVk = handler;
			}),
		enableShareOk: (handler) =>
			set((state) => {
				state.sharingHandlerOk = handler;
			}),
		enableShareTelegram: (handler) =>
			set((state) => {
				state.sharingHandlerTelegram = handler;
			}),
		enableLinkCopy: () =>
			set((state) => {
				state.linkCopyEnabled = true;
			})
	}))
);

export const useSharingHandlers = () => {
	const shareVk = useSharingHandlersStore((state) => state.sharingHandlerVk);
	const shareOk = useSharingHandlersStore((state) => state.sharingHandlerOk);
	const shareTelegram = useSharingHandlersStore((state) => state.sharingHandlerTelegram);
	const linkCopyEnabled = useSharingHandlersStore((state) => state.linkCopyEnabled);

	return {
		sharingHandlers: new Map([
			["vk", shareVk],
			["ok", shareOk],
			["telegram", shareTelegram]
		] as const),
		linkCopyEnabled
	};
};

export const sharingHandlersStore = {
	getState: useSharingHandlersStore.getState,
	setState: useSharingHandlersStore.setState,
	subscribe: useSharingHandlersStore.subscribe
};
