import { MantineColor } from "@mantine/core";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { useMemo } from "react";

type Store = State;

type State = {
	queue: readonly GlobalMessageProps[];
};

type GlobalMessage = {
	id: string;
	color: MantineColor;
	priority?: number;
	dismissable?: boolean;
	meta?: Record<string, unknown>;
};

export type GlobalMessageProps = GlobalMessage & {
	priority: number;
	dismissable: boolean;
};

const DEFAULT_STATE: State = {
	queue: []
};

const useStore = create<Store>()(
	immer(() => ({
		...DEFAULT_STATE
	}))
);

const enqueue = (newMessage: GlobalMessage) =>
	useStore.setState((state) => {
		const isPresent = state.queue.some((m) => m.id === newMessage.id);
		if (isPresent) return;
		const message: GlobalMessageProps = { priority: 0, dismissable: true, ...newMessage };
		state.queue.push(message);
		state.queue.sort((a, b) => b.priority - a.priority);
	});

const dequeue = (id: string) =>
	useStore.setState((state) => {
		state.queue = state.queue.filter((m) => m.id !== id);
	});

export const useGlobalMessagesQueue = () => {
	const queue = useStore((state) => state.queue);
	const actions = useMemo(() => ({ enqueue, dequeue }), []);
	return [queue, actions] as const;
};

export const globalMessagesStore = {
	getState: useStore.getState,
	setState: useStore.setState,
	subscribe: useStore.subscribe
};
