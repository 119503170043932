import "./index.css";
import "./index.scss";

import React from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

import App from "./App";
import "./dayjs";
import * as Sentry from "@sentry/react";
import { apiBasePath, routerBasePath } from "@app/constants";
import { BrowserOptions } from "@sentry/react";
import log from "loglevel";

globalThis.log = log;
log.enableAll();
log.setDefaultLevel(
	import.meta.env.VITE_APP_ENV_OVERRIDE_NAME == "prod"
		? "warn"
		: import.meta.env.VITE_APP_ENV_OVERRIDE_NAME == "dev"
			? "trace"
			: "debug"
);

log.info(`Environment: %o`, import.meta.env);
log.info(`routerBasePath: %s`, routerBasePath);
log.info(`BuildId: %s`, import.meta.env.VITE_APP_BUILD_ID);

// const sentryUrl =
// 	import.meta.env.BASE_URL == null ||
// 	import.meta.env.BASE_URL === "/" ||
// 	import.meta.env.BASE_URL === "" ||
// 	import.meta.env.BASE_URL === "./"
// 		? "/api/s_tunnel"
// 		: import.meta.env.BASE_URL.startsWith("http")
// 			? new URL("/api/s_tunnel", import.meta.env.BASE_URL).toString()
// 			: new URL("/api/s_tunnel", new URL(import.meta.env.BASE_URL, window.location.origin).toString()).toString();

const sentryUrl = apiBasePath == "" ? "/api/s_tunnel" : new URL("/api/s_tunnel", apiBasePath).toString();

const integrations: Sentry.BrowserOptions["integrations"] = [
	Sentry.browserTracingIntegration(),
	Sentry.replayIntegration({
		maskAllText: false,
		blockAllMedia: false
	})
];

const sentryInitConfig: BrowserOptions = {
	release: import.meta.env.VITE_APP_BUILD_ID,
	environment:
		import.meta.env.VITE_APP_ENV_OVERRIDE_NAME === "prod"
			? "production"
			: import.meta.env.VITE_APP_ENV_OVERRIDE_NAME,
	dsn: "https://7cb05911025b9bc5fc5c5182ca0542d4@sentry.fxgam.es/4",
	tunnel: sentryUrl,
	integrations: integrations,
	// Performance Monitoring
	tracesSampleRate: 0.25, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	// tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: import.meta.env.VITE_APP_ENV_OVERRIDE_NAME === "prod" ? 0.1 : 100, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};

log.info("Initializing sentry with config: %o", sentryInitConfig);

Sentry.init(sentryInitConfig);

// Функция, которая предотвращает вызов контекстного меню
function disableContextMenu(event: MouseEvent) {
	event.preventDefault();
}

// Назначение обработчика события на весь документ и окно
document.addEventListener("contextmenu", disableContextMenu);
window.addEventListener("contextmenu", disableContextMenu);

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

const Router = import.meta.env.VITE_APP_BUILD_MODE === "standalone" ? HashRouter : BrowserRouter;

root.render(
	<Router
		basename={routerBasePath}
		future={{
			// https://reactrouter.com/6.30.0/upgrading/future#v7_starttransition
			v7_startTransition: true,
			// https://reactrouter.com/6.30.0/upgrading/future#v7_relativesplatpath
			v7_relativeSplatPath: true
		}}
	>
		<App />
	</Router>
);

if (import.meta.env.VITE_APP_NO_SW != null) {
	log.info("SW disabled");
} else {
	if ("serviceWorker" in navigator) {
		log.info("SW registering");

		navigator.serviceWorker
			.register(import.meta.env.PROD ? "/service-worker.js" : "/dev-sw.js?dev-sw", {
				type: import.meta.env.MODE === "production" ? "classic" : "module"
			})
			.then(log.info)
			.catch(log.error);
	}
}
