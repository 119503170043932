import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

type Store = State & Actions;
type State = {
	// brandName: string;
	// singleGameSite: boolean;
	isWebPlayLoginEnabled: boolean;
	isHeaderLoginEnabled: boolean;
	isUserProfileSettingsButtonEnabled: boolean;
};
type Actions = {
	enableWebPlayLogin: () => void;
	updateHeaderLogin: (newState: boolean) => void;
	updateUserProfileSettingsButton: (newState: boolean) => void;
	// updateSingleGameSite: (newState: boolean) => void;
	// updateBrandName: (newState: string) => void;
};

const DEFAULT_STATE: State = {
	// brandName: "",
	// singleGameSite: import.meta.env.VITE_APP_SITE_MODE === "restricted",
	isWebPlayLoginEnabled: false,
	isHeaderLoginEnabled: true,
	isUserProfileSettingsButtonEnabled: true
};

const useAppStore = create<Store>()(
	immer((set) => ({
		...DEFAULT_STATE,
		enableWebPlayLogin: () =>
			set((state) => {
				state.isWebPlayLoginEnabled = true;
			}),
		updateHeaderLogin: (newState) =>
			set((state) => {
				state.isHeaderLoginEnabled = newState;
			}),
		updateUserProfileSettingsButton: (newState) =>
			set((state) => {
				state.isUserProfileSettingsButtonEnabled = newState;
			})
		// updateSingleGameSite: (newState) =>
		// 	set((state) => {
		// 		state.singleGameSite = newState;
		// 	}),
		// updateBrandName: (newState) =>
		// 	set((state) => {
		// 		state.brandName = newState;
		// 	})
	}))
);

export const useWebPlayLoginState = () => useAppStore((state) => state.isWebPlayLoginEnabled);

export const useHeaderLoginState = () => {
	const update = useAppStore((state) => state.updateHeaderLogin);
	const enabled = useAppStore((state) => state.isHeaderLoginEnabled);
	const enable = () => update(true);
	const disable = () => update(false);
	const reset = () => update(DEFAULT_STATE.isHeaderLoginEnabled);

	return [enabled, { enable, disable, reset }] as const;
};

export const useUserProfileSettingsButtonState = () => {
	const update = useAppStore((state) => state.updateUserProfileSettingsButton);
	const enabled = useAppStore((state) => state.isUserProfileSettingsButtonEnabled);
	const enable = () => update(true);
	const disable = () => update(false);
	const reset = () => update(DEFAULT_STATE.isUserProfileSettingsButtonEnabled);

	return [enabled, { enable, disable, reset }] as const;
};

export const appStore = {
	getState: useAppStore.getState,
	setState: useAppStore.setState,
	subscribe: useAppStore.subscribe
};
